import * as THREE from 'three';

export function createRobot() {
  const robotGroup = new THREE.Group();

  // Head - smooth and elegant
  const headGeometry = new THREE.SphereGeometry(0.25, 32, 32);
  const headMaterial = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    metalness: 0.7,
    roughness: 0.2,
    emissive: 0x111111,
    emissiveIntensity: 0.1
  });
  const head = new THREE.Mesh(headGeometry, headMaterial);
  head.position.y = 1.1;
  robotGroup.add(head);

  // Eyes - glowing purple
  const eyeGeometry = new THREE.SphereGeometry(0.05, 32, 32);
  const eyeMaterial = new THREE.MeshStandardMaterial({
    color: 0x9c27b0,
    emissive: 0x9c27b0,
    emissiveIntensity: 1,
    metalness: 1,
    roughness: 0
  });

  const leftEye = new THREE.Mesh(eyeGeometry, eyeMaterial);
  leftEye.position.set(-0.1, 1.15, 0.2);
  robotGroup.add(leftEye);

  const rightEye = new THREE.Mesh(eyeGeometry, eyeMaterial);
  rightEye.position.set(0.1, 1.15, 0.2);
  robotGroup.add(rightEye);

  // Elegant crown-like antenna
  const antennaGeometry = new THREE.ConeGeometry(0.1, 0.15, 8);
  const antennaMaterial = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    metalness: 0.9,
    roughness: 0.1,
    emissive: 0x9c27b0,
    emissiveIntensity: 0.3
  });
  const antenna = new THREE.Mesh(antennaGeometry, antennaMaterial);
  antenna.position.set(0, 1.4, 0);
  robotGroup.add(antenna);

  // Body - elegant and flowing
  const bodyGeometry = new THREE.CylinderGeometry(0.2, 0.3, 0.8, 32, 1, true);
  const bodyMaterial = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    metalness: 0.7,
    roughness: 0.2,
    emissive: 0x111111,
    emissiveIntensity: 0.1
  });
  const body = new THREE.Mesh(bodyGeometry, bodyMaterial);
  body.position.y = 0.6;
  robotGroup.add(body);

  // Ethereal core ring
  const ringGeometry = new THREE.TorusGeometry(0.25, 0.02, 32, 64);
  const ringMaterial = new THREE.MeshStandardMaterial({
    color: 0x9c27b0,
    emissive: 0x9c27b0,
    emissiveIntensity: 0.8,
    metalness: 1,
    roughness: 0,
    transparent: true,
    opacity: 0.8
  });
  const ring = new THREE.Mesh(ringGeometry, ringMaterial);
  ring.position.y = 0.6;
  ring.rotation.x = Math.PI / 2;
  robotGroup.add(ring);

  // Arms - flowing and graceful
  const armGeometry = new THREE.CylinderGeometry(0.04, 0.03, 0.4, 32);
  const armMaterial = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    metalness: 0.7,
    roughness: 0.2
  });

  const leftArm = new THREE.Mesh(armGeometry, armMaterial);
  leftArm.position.set(-0.3, 0.8, 0);
  robotGroup.add(leftArm);

  const rightArm = new THREE.Mesh(armGeometry, armMaterial);
  rightArm.position.set(0.3, 0.8, 0);
  robotGroup.add(rightArm);

  // Hands - delicate and glowing
  const handGeometry = new THREE.SphereGeometry(0.04, 32, 32);
  const handMaterial = new THREE.MeshStandardMaterial({
    color: 0x9c27b0,
    emissive: 0x9c27b0,
    emissiveIntensity: 0.5,
    metalness: 1,
    roughness: 0
  });

  const leftHand = new THREE.Mesh(handGeometry, handMaterial);
  leftHand.position.set(-0.3, 0.6, 0);
  robotGroup.add(leftHand);

  const rightHand = new THREE.Mesh(handGeometry, handMaterial);
  rightHand.position.set(0.3, 0.6, 0);
  robotGroup.add(rightHand);

  // Legs - elegant and slim
  const legGeometry = new THREE.CylinderGeometry(0.05, 0.03, 0.4, 32);
  const legMaterial = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    metalness: 0.7,
    roughness: 0.2
  });

  const leftLeg = new THREE.Mesh(legGeometry, legMaterial);
  leftLeg.position.set(-0.15, 0.2, 0);
  robotGroup.add(leftLeg);

  const rightLeg = new THREE.Mesh(legGeometry, legMaterial);
  rightLeg.position.set(0.15, 0.2, 0);
  robotGroup.add(rightLeg);

  // Feet - graceful and glowing
  const footGeometry = new THREE.SphereGeometry(0.05, 32, 32);
  const footMaterial = new THREE.MeshStandardMaterial({
    color: 0x9c27b0,
    emissive: 0x9c27b0,
    emissiveIntensity: 0.5,
    metalness: 1,
    roughness: 0
  });

  const leftFoot = new THREE.Mesh(footGeometry, footMaterial);
  leftFoot.position.set(-0.15, 0.05, 0);
  robotGroup.add(leftFoot);

  const rightFoot = new THREE.Mesh(footGeometry, footMaterial);
  rightFoot.position.set(0.15, 0.05, 0);
  robotGroup.add(rightFoot);

  // Animation
  let time = 0;
  robotGroup.userData.animate = () => {
    time += 0.02;

    // Gentle floating animation
    robotGroup.position.y = Math.sin(time) * 0.05;

    // Ethereal ring rotation
    ring.rotation.z = time;
    ring.scale.setScalar(1 + Math.sin(time * 2) * 0.05);

    // Graceful arm movement
    leftArm.rotation.x = Math.sin(time) * 0.15;
    rightArm.rotation.x = Math.sin(time + Math.PI) * 0.15;
    leftHand.position.z = Math.sin(time) * 0.05;
    rightHand.position.z = Math.sin(time + Math.PI) * 0.05;

    // Pulsing eyes
    eyeMaterial.emissiveIntensity = 0.8 + Math.sin(time * 2) * 0.2;

    // Glowing accents
    handMaterial.emissiveIntensity = 0.5 + Math.sin(time * 1.5) * 0.2;
    footMaterial.emissiveIntensity = 0.5 + Math.sin(time * 1.5) * 0.2;
    antennaMaterial.emissiveIntensity = 0.3 + Math.sin(time * 2) * 0.1;
  };

  return robotGroup;
}

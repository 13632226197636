import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import RobotModel from '../components/RobotModel/RobotModel';
import { socialConfig } from '../config/social';
import '../styles/Home.css';

const Home = () => {
  const [particles, setParticles] = useState<{ x: number; y: number }[]>([]);

  useEffect(() => {
    const initialParticles = Array.from({ length: 50 }, () => ({
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight
    }));
    setParticles(initialParticles);

    const interval = setInterval(() => {
      setParticles(prev => prev.map(particle => ({
        x: particle.x + (Math.random() - 0.5) * 2,
        y: particle.y + (Math.random() - 0.5) * 2
      })));
    }, 50);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="home-container">
      <div className="particles-container">
        {particles.map((particle, index) => (
          <div
            key={index}
            className="particle"
            style={{
              left: `${particle.x}px`,
              top: `${particle.y}px`
            }}
          />
        ))}
      </div>
      <div className="home-content">
        <div className="logo-section">
          <RobotModel status="approved" />
          <h1>Cyrax</h1>
        </div>
        <nav className="nav-links">
          <Link to="/verse">verse</Link>
          <span>•</span>
          <Link to="/whitepaper">whitepaper</Link>
          <span>•</span>
          <Link to="/agents">agents</Link>
          <span>•</span>
          <a href={socialConfig.twitter} target="_blank" rel="noopener noreferrer">twitter</a>
        </nav>
      </div>
      <div className="powered-by">
        powered by <a href={socialConfig.twitter} target="_blank" rel="noopener noreferrer">arc</a>
      </div>
    </div>
  );
};

export default Home;
